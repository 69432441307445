<template>
  <div class="auth-wrapper auth-v1 px-2" style="min-height: 100vh">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo align-items-center" to="/map">
          <!-- <logo /> -->
          <b-img :src="logo" alt="Ecoplot" width="40" height="40"></b-img>
          <h2 class="brand-text text-primary ml-1 mb-0">Ecoplot</h2>
        </b-link>

        <b-card-title class="mb-1">{{ $t('welcome_to_ecoplot') }} 👋</b-card-title>
        <b-card-text class="mb-2">{{ $t('please_signin') }}</b-card-text>
        <!-- <button @click.stop="register">resgister</button> -->

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="login">
            <!-- email -->
            <b-form-group label-for="email" :label="$t('email')">
              <validation-provider #default="{ errors }" name="Email" rules="required|email">
                <b-form-input tabindex="1" id="email" v-model="username" name="login-email" :state="errors.length > 0 ? false : null" placeholder="john@example.com" autofocus />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">{{ $t('password') }}</label>
                <b-link>
                  <small tabindex="4">{{ $t('forgot_password') }}</small>
                </b-link>
              </div>
              <validation-provider #default="{ errors }" name="Password" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input tabindex="2" id="password" v-model="password" :type="passwordFieldType" class="form-control-merge" :state="errors.length > 0 ? false : null" name="login-password" :placeholder="$t('password')" />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">Remember Me</b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <b-button tabindex="3" variant="primary" type="submit" block :disabled="invalid || showloading">
              <span v-show="!showloading" class="text-signin">{{ $t('signin') }}</span>
              <span v-show="showloading" class="loading-signin">
                <div style="width: 20px; height: 20px" class="spinner-border text-light" role="status"></div>
              </span>
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>{{ $t('new_on_our_platform') }} </span>
          <b-link>
            <span>{{ $t('create_an_account') }}</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <!-- <div class="divider-text">or</div> -->
        </div>

        <!-- social button -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button variant="facebook" @click="facebookLogin">
            <facebook-icon style="display: block; width: 14px; height: 14px" />
          </b-button>
          <b-button variant="google" @click="googleLogin">
            <google-icon style="display: block; width: 14px; height: 14px" />
          </b-button>
        </div> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
const axios = require('axios')
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import { ECOPLOT_API } from '@/constants/urls'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BImg } from 'bootstrap-vue'
// import Logo from '@/@core/layouts/components/Logo.vue'
import { required, email } from '@/@core/utils/validations/validations.js'
import { togglePasswordVisibility } from '@/@core/mixins/ui/forms'
import FacebookIcon from '/static/images/login/facebook.svg'
import GoogleIcon from '/static/images/login/google.svg'

export default {
  components: { BButton, BForm, BFormInput, BFormGroup, BCard, BCardTitle, BLink, BCardText, BInputGroup, BInputGroupAppend, BImg, ValidationProvider, ValidationObserver, FacebookIcon, GoogleIcon },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: '',
      password: '',
      // status: '',
      required,
      email,
      logo: require('/static/besec.png'),
      showloading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    userAccount() {
      return this.$store.state.ecoplot.user
    },
    countErrorLogin() {
      return this.$store.state.ecoplot.countErrorLogin
    },
  },
  watch: {
    userAccount: {
      deep: true,
      handler() {
        if (!this.userAccount) {
          this.showloading = false
        }
      },
    },
    countErrorLogin(countErrorLogin) {
      this.showloading = false
    },
  },
  methods: {
    // async register() {
    //   let data = {
    //     full_name: 'tc_demo',
    //     password: 'Abc@123',
    //     username: 'tc_demo@besec.co.jp',
    //   }
    //   const response =  await axios.post(`${ECOPLOT_API}/users/`, data)
    // },
    // login() {
    //   this.showloading = true
    //   this.$store.dispatch('ecoplot/LOGIN', { username: this.username, password: this.password })
    // },
    async login() {
      if (this.showloading) return
      this.showloading = true
      let user
      let toastMessage = 'Login failed!'
      try {
        const getToken = await axios.post(`${ECOPLOT_API}/login/`, { username: this.username, password: this.password })
        let token = getToken.data.token
        localStorage.setItem('django-authentication-token', token)
        const getUser = await axios.get(`${ECOPLOT_API}/users/current/`, { headers: { Authorization: `Token ${token}` } })
        if (!getUser.data.is_paid) {
          toastMessage = 'You are using FREE account which cannot be used to access Ecoplot Pro.'
        } else {
          localStorage.setItem('accept-pro-policy', getUser.data.accept_pro_policy)
          user = getUser.data
        }
      } catch {}
      this.$store.commit('ecoplot/CHANGE_USER', { user: user })
      if (!user) {
        localStorage.removeItem('django-authentication-token')
        localStorage.removeItem('accept-pro-policy')
        this.$toast({ component: ToastificationContent, props: { title: 'Error', icon: 'BellIcon', text: toastMessage, variant: 'danger', timeout: 1000 } })
      }
      this.showloading = false
    },
    facebookLogin() {
      FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          this.$store.dispatch('ecoplot/FACEBOOK_LOGIN', { code: response.authResponse.accessToken })
        } else {
          FB.login(
            (response) => {
              if (response.status === 'connected') {
                this.$store.dispatch('ecoplot/FACEBOOK_LOGIN', { code: response.authResponse.accessToken })
              }
            },
            { scope: 'public_profile,email' }
          )
        }
      })
    },
    googleLogin() {
      window.auth2
        .grantOfflineAccess()
        .then((authResult) => {
          if (authResult.code) {
            this.$store.dispatch('ecoplot/GOOGLE_LOGIN', { code: authResult.code })
          }
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@/@core/scss/vue/pages/page-auth.scss';
</style>
